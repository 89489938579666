<template>
  <div class="batch_order">
    <headNav></headNav>
    <div class="flex_m mtabs mb30" v-if="$store.state.drawerType === 'add'">
      <div class="tab_li flex1" @click="jumpToPlaceForm">普通发货</div>
      <div class="tab_li flex1 active">批量发货</div>
    </div>
    <van-cell-group class="block_base">
      <van-cell center is-link @click="toAddress('', 'deliver')">
        <template #title>
          <div
            class="ml20"
            v-if="batchDataArr.fhArr.sendersName && batchDataArr.fhArr.sendersPhone"
          >
            {{ batchDataArr.fhArr.sendersName || '' }}
            {{ batchDataArr.fhArr.sendersPhone || '' }}
          </div>
          <div class="ml20 f888 pl10" v-else>请填写发货信息</div>
        </template>
        <template #icon>
          <van-tag type="primary" class="size26">发</van-tag>
        </template>
        <template #label v-if="batchDataArr.fhArr.sendersCityName">
          <div class="ml20 fz28 f333">
            {{ sendsAddr }}
          </div>
        </template>
      </van-cell>
      <van-cell is-link @click="chooseTime" class="reflex">
        <template #title>预计发货时间</template>
        <template #default
          ><span class="f333"
            >{{ batchDataArr.fhArr.sendersDate }} {{ batchDataArr.fhArr.sendersTime }}</span
          ></template
        >
      </van-cell>
    </van-cell-group>
    <van-cell-group
      class="mt30 block_base"
      v-for="(item, index) in batchDataArr.shList"
      :key="index"
    >
      <van-cell center>
        <template #title>
          <div
            class="ml20"
            v-if="item.recipientName && item.recipientPhone"
            @click="() => toAddress(item, 'receive', index)"
          >
            {{ item.recipientName || '' }}
            {{ item.recipientPhone || '' }}
          </div>
          <div class="ml20 f888 pl10" v-else @click="() => toAddress(item, 'receive', index)">
            请填写收货信息
          </div>
        </template>
        <template #icon>
          <van-tag type="success" class="size26">收</van-tag>
        </template>
        <template #label>
          <div
            class="ml20 fz28 f333"
            v-if="item.recipientCityName"
            @click="() => toAddress(item, 'receive', index)"
          >
            {{ receiptAddr(item) }}
          </div>
        </template>
        <template #right-icon>
          <van-icon
            v-if="!item.orderId"
            name="delete-o"
            class="search-icon fz40"
            @click="() => deleteLine(item, index)"
          />
        </template>
      </van-cell>
      <van-cell is-link @click="() => jumbToBatchOne(item, index)" class="reflex">
        <template #title v-if="!item.totalVolume">
          <span class="f888">选择货物和服务</span>
        </template>
        <template #title v-else>{{ service(item) }}</template>
      </van-cell>
    </van-cell-group>
    <div class="mt30 block_base add_more" @click="addMoreLine">
      <i class="iconfont icon-jiahao mr10"></i>添加收货人
    </div>
    <van-row class="mt30" type="flex" justify="center">
      <van-col :span="2">
        <van-checkbox v-model="agreementFlag" @click="changeAgreement"></van-checkbox>
      </van-col>
      <van-col :span="16">
        <div class="tips fz24">
          我已阅读并同意
          <span class="icon_m" @click="() => jumpToPage('1')">《运输条款》</span>
          <span class="icon_m" @click="() => jumpToPage('2')">《隐私政策》</span>
        </div>
      </van-col>
    </van-row>
    <div class="bottom_btn_block flex_lr_m bg-fff" v-if="isShowBottom">
      <div class="flex_m" @click="showPriceList">
        <div class="plr20 tr">
          <div>
            预计<span class="ff82d2d">￥{{ batchDisAll.expenses || '--' }}元</span>
          </div>
          <div>
            已减<span class="ff82d2d">{{ batchDisAll.dis || '0' }}</span
            >元
          </div>
        </div>
        <i class="flex1 iconfont fz48" :class="hasShow ? 'icon-xiangshang' : 'icon-xiala'"></i>
      </div>
      <div class="sub_btn" @click="submitBatch">立即下单</div>
    </div>
    <van-action-sheet v-model="hasShow" title="价格明细">
      <div class="content">
        <div>共{{ this.$store.state.batchConForm.shList.length }}票货物</div>
        <ul
          class="price_box"
          v-for="(item, index) in this.$store.state.batchConForm.shList"
          :key="index"
        >
          <li class="head ellipsis">
            {{
              batchDataArr.fhArr.sendersDistrictName
                ? batchDataArr.fhArr.sendersProvinceName +
                  '' +
                  batchDataArr.fhArr.sendersCityName +
                  '' +
                  batchDataArr.fhArr.sendersDistrictName
                : ''
            }}
            -
            {{
              item.recipientDistrictName
                ? item.recipientProvinceName +
                  '' +
                  item.recipientCityName +
                  '' +
                  item.recipientDistrictName
                : ''
            }}
          </li>
          <li>{{ item.totalWeight || '-' }}kg/{{ item.totalVolume || '-' }}m³</li>
          <li class="xlr_m">
            <span>优惠券</span>
            <span class="pri_span">{{
              item.batchCouponMoney ? '-￥' + item.batchCouponMoney : '￥0'
            }}</span>
          </li>
          <li class="xlr_m">
            <span class="flex_m" @click="showRuler"
              >批量下单优惠<span class="iconfont icon-wenti fz38 pri_span"></span
            ></span>
            <span class="pri_span">{{ item.collectFee ? '-￥' + item.collectFee : '￥0' }}</span>
          </li>
          <li class="xlr_m">
            <span>费用预估</span>
            <span>{{ item.batchTotalAll ? '￥' + item.batchTotalAll : '-' }}</span>
          </li>
        </ul>
      </div>
    </van-action-sheet>
    <DateTimeSelect ref="timeSelectRef" @select="getDateTime"></DateTimeSelect>
    <AddressListCmp
      ref="addressRefM"
      :activeName="activeName"
      :xhrParams="xhrData"
      :isRadio="isRadio"
      :isBatch="isBatch"
      :isShowAddrModal="isShowAddrModal"
      :selectedAddr="selectedAddr"
      @changeAddrModal="changeAddrModal"
      @confirm="confirm"
    ></AddressListCmp>
  </div>
</template>
<script>
import headNav from '@/components/mobile/headNav.vue'
import AddressListCmp from '@/components/addressListCmp/AddressListCmp.vue' //引入选择产品弹窗
import DateTimeSelect from './components/DateTimeSelect.vue'
import { BATCHORDERSTORE } from '@/assets/js/orderLibrary'
import { getBatchOrderForm, setBatchOrderForm } from '@/assets/js/storage'
import {
  getDetailStr,
  showBatchSubmitPrice,
  initSendersDate,
  initArea,
  getBatchOrderDetial,
  costAssessment,
  batchSubmit,
  confirnAddress,
  areaFilterVersion
} from '@/assets/js/order'
import { getUserStore, getAgreementState, setAgreementState } from '@/assets/js/storage'
import { Dialog } from 'vant'
const userData = getUserStore()
export default {
  name: 'batchFormOrder',
  components: {
    AddressListCmp,
    DateTimeSelect,
    headNav
  },
  data() {
    return {
      isBatch: true, //是批量下单
      faIdx: -1,
      batchDataArr: BATCHORDERSTORE, //所有表单数据
      agreementFlag: false,
      hasShow: false, //是否展开价格列表
      activeName: 'deliver', //当前地址簿选择的类型deliver,receive
      isRadio: true, //是否为单选框
      xhrData: {
        corpId: userData.corpId || '',
        userId: userData.id || ''
      },
      selectedAddr: [],
      isRadio: true, //true为地址簿选择弹窗，false为添加多发货人弹窗
      isShowAddrModal: false, //是否显示地址簿弹窗
      isShowBottom: true //是否显示底部下单按钮，
    }
  },
  created() {
    areaFilterVersion(this) //查询地址版本
    this.agreementFlag = getAgreementState() === '1' ? true : false
    let routeParams = this.$route.params
    if (!!routeParams.isAgree) {
      this.agreementFlag = true
      setAgreementState('1')
    }
    if (routeParams.allBatchDatas) {
      //从单个订单确定返回到批量下单页来
      let batchForm = routeParams.allBatchDatas
      this.batchDataArr = batchForm
    } else if (!!routeParams.parentNo || !!routeParams.orderId) {
      this.$store.state.drawerType = 'edit'
      //从订单列表来
      let newP = {
        parentOrderNo: routeParams.parentNo || '',
        orderId: routeParams.orderId || ''
      }
      getBatchOrderDetial(this, newP, this.callbackBatch)
    } else {
      //新增
      let newBatchForm = BATCHORDERSTORE
      //设置初始预计发货时间段
      let sendsDate = initSendersDate()
      newBatchForm.fhArr = {
        ...newBatchForm.fhArr,
        ...sendsDate
      }
      this.batchDataArr = newBatchForm
      initArea(this.areaCallback)
    }
  },
  computed: {
    sendsAddr() {
      let addr =
        (this.batchDataArr.fhArr.sendersProvinceName || '') +
        '' +
        (this.batchDataArr.fhArr.sendersCityName || '') +
        '' +
        (this.batchDataArr.fhArr.sendersDistrictName || '') +
        '' +
        (this.batchDataArr.fhArr.sendersDetailaddress || '')
      return addr
    },
    batchDisAll() {
      if (this.$store.state.batchConForm.shList.length === 0) return
      return showBatchSubmitPrice(this.$store.state.batchConForm.shList)
    }
  },
  methods: {
    changeAddrModal(flag) {
      this.isShowAddrModal = flag || false
    },
    callbackBatch(res) {
      costAssessment(this, res)
      this.batchDataArr = res
      this.$store.state.batchConForm = res
    },
    changeAgreement() {
      setAgreementState(this.agreementFlag ? 1 : 0)
    },
    areaCallback(dataArr) {
      this.batchDataArr.fhArr = {
        ...this.batchDataArr.fhArr,
        ...dataArr.sendsArr
      }
      this.batchDataArr.shList[0] = {
        ...this.batchDataArr.shList[0],
        ...dataArr.receiveArr
      }
      this.batchDataArr = { ...this.batchDataArr }
      setBatchOrderForm(this.batchDataArr)
    },
    confirm(res) {
      if (res.obj === null) return
      const addr = res.obj
      this.isShowBottom = true //显示底部的下单按钮
      confirnAddress(this, addr)
    },
    jumpToPlaceForm() {
      this.$router.push({
        path: '/m_payorder'
      })
    },
    receiptAddr(item) {
      let addr =
        (item.recipientProvinceName || '') +
        '' +
        (item.recipientCityName || '') +
        '' +
        (item.recipientDistrictName || '') +
        '' +
        (item.recipientDetailaddress || '')
      return addr
    },
    service(item) {
      let str = getDetailStr(item)
      return str
    },
    // 进入收、发地址页面
    toAddress(item, type, faIdx) {
      if (!item) {
        item = this.batchDataArr.shList[0]
      }
      if (item.orderSn) {
        this.$toast('已下的单在批量操作中不能修改')
        return
      }
      let navTitle = type == 0 ? '添加发货地址' : '添加收货地址'
      let oneDatas = {
        ...this.batchDataArr.fhArr,
        ...this.batchDataArr.shList[faIdx]
      }
      this.isRadio = true
      const self = this
      if (
        type === 'deliver' &&
        this.batchDataArr.shList.length > 0 &&
        !this.batchDataArr.fhArr.parentOrderNo
      ) {
        let index = this.batchDataArr.shList.findIndex(item => item.lineId)
        if (index !== -1) {
          //发货如果有选择过线路的，则清空收货数据
          Dialog.confirm({
            title: '温馨提示',
            message: '修改发货地址会删除已选择好产品的收货人',
            confirmButtonText: '确定',
            cancelButtonText: '再考虑下'
          })
            .then(() => {
              self.jumpAddrs(navTitle, self.batchDataArr, oneDatas, self.isBatch, faIdx, type)
            })
            .catch(() => {})
          return
        }
      }
      if (!!item.orderId) {
        this.$toast('已下单的发货地址在批量操作中不能修改')
        return
      }
      self.jumpAddrs(navTitle, self.batchDataArr, oneDatas, self.isBatch, faIdx, type)
    },
    jumpAddrs(navTitle, batchDataArr, oneDatas, isBatch, faIdx, type) {
      this.$router.push({
        name: 'addressManage',
        meta: {
          title: navTitle
        },
        params: {
          allBatchDatas: batchDataArr,
          addrDatas: oneDatas,
          isBatch: isBatch,
          faIdx: faIdx,
          addressType: type,
          optionType: 'change'
        }
      })
    },
    chooseTime() {
      if (!!this.batchDataArr.fhArr.parentOrderNo) {
        this.$toast('已下单的发货地址在批量操作中不能修改')
        return
      }
      this.$refs.timeSelectRef.show()
    },
    updataService(datas) {
      this.batchDataArr = {
        ...this.batchDataArr,
        ...datas
      }
      setOrderForm(this.batchDataArr)
    },
    getDateTime(dateTime) {
      const year = new Date().getFullYear()
      const nav = dateTime['nav'].text
      const day = nav.split('   ')[1]

      const mm = day.split('月')[0]
      const dd = day.split('月')[1].split('日')[0]
      const timeRange = dateTime['child'].text
      this.batchDataArr.fhArr.sendersDate = `${year}-${mm}-${dd}`
      this.batchDataArr.fhArr.sendersTime = `${timeRange}`
    },
    jumbToBatchOne(item, faIdx) {
      if (item.orderId) {
        this.$toast('已下的单在批量操作中不能修改')
        return
      }
      let oneDatas = {
        ...this.batchDataArr.fhArr,
        ...this.batchDataArr.shList[faIdx]
      }
      if (!oneDatas.sendersName || !oneDatas.sendersPhone || !oneDatas.sendersAddress.length) {
        this.$toast('请填写发货信息')
        return
      }
      if (!oneDatas.sendersDate || !oneDatas.sendersTime) {
        this.$toast('请选择预计发货实践')
        return
      }
      if (
        !oneDatas.recipientName ||
        !oneDatas.recipientPhone ||
        !oneDatas.recipientAddress.length
      ) {
        this.$toast('请填写该条发货地址信息')
        return
      }
      setBatchOrderForm(this.batchDataArr) //设置内存
      this.$router.push({
        name: 'm_batchOne',
        params: {
          allBatchDatas: this.batchDataArr,
          oneDatas: oneDatas,
          isBatch: this.isBatch,
          faIdx: faIdx
        }
      })
    },
    showPriceList() {
      this.hasShow = !this.hasShow
    },
    deleteLine(item, index) {
      let self = this
      if (self.batchDataArr.shList.length === 1) {
        self.$toast('最少保留一条收货信息', 5000)
        return
      }
      Dialog.confirm({
        title: '温馨提示',
        message: '确认本次不给我发货了吗',
        confirmButtonText: '确定',
        cancelButtonText: '再考虑下'
      })
        .then(() => {
          self.batchDataArr.shList.splice(index, 1)
          setBatchOrderForm(self.batchDataArr)
          costAssessment(self, self.batchDataArr)
        })
        .catch(res => {
          self.$toast(res.msg || '')
        })
    },
    addMoreLine() {
      this.activeName = 'receive'
      this.isRadio = false //多选框
      this.activeName = 'receive'
      this.activeType = false
      this.selectedAddr = this.batchDataArr.shList
      this.isShowBottom = false //暂时隐藏底部的下单按钮
      this.isShowAddrModal = true
    },
    submitBatch() {
      batchSubmit(this, this.batchDataArr)
    },
    jumpToPage(type) {
      this.$router.push({
        name: 'm_orderagreement',
        meta: {
          title: type === '1' ? '运输条款' : type === '2' ? '隐私政策' : '收货标准'
        },
        params: {
          tabType: type || '1',
          path: 'm_batchorder',
          allDatas: this.batchDataArr
        }
      })
    },
    showRuler() {
      this.$dialog.alert({
        title: '批量下单费用减免规则',
        message:
          '在批量下单场景下，同一取货地址，同一取货时间的多个子订单，适用批量提货优惠。具体优惠方案为：从第二个子订单开始，每个订单减免40元。如您同时享有优惠（如优惠券），其他优惠在批量提货优惠减免后计算，实际付款金额以最终计价金额为准。'
      })
    }
  }
}
</script>
<style lang="less" scoped>
.batch_order {
  padding-bottom: 150px;
}
.add_more {
  padding: 30px 0;
  font-size: 30px;
  color: #007ec5;
  text-align: center;
}
.bottom_btn_block {
  position: fixed;
  bottom: 0px;
  width: 100%;
  box-sizing: border-box;
  z-index: 9999;
}
.sub_btn {
  width: 220px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  font-size: 30px;
  color: #fff;
  background-color: #007ec5;
}
.content {
  max-height: 750px;
  overflow-y: auto;
  padding: 20px 30px 120px;
}
.price_box {
  width: 100%;
  border-radius: 10px;
}
.price_box li {
  font-size: 24px;
}
</style>
